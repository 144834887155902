export default {
  BUTTON_PRIMARY_CLASS: 'btn btn--primary',
  BUTTON_SECONDARY_CLASS: 'btn btn--secondary',

  SEARCH_BOX_CLASS: 'search-form search-bar__form',
  SEARCH_BOX_FORM_CLASS: 'search-form__input-wrapper',
  SEARCH_BOX_INPUT_CLASS: 'search-form__input search-bar__input is-filled',
  SEARCH_BOX_BUTTON_CLASS: 'search-button__submit search-form__submit',
  SEARCH_BOX_CLEAR_BUTTON: 'Clear',

  FACET_TITLE_ICON: '<rt-virtual template="icons/facet-icon" />',
  MIN_PRICE: 'Min',
  MAX_PRICE: 'Max',

  FACET_BAR_WHEELS_TIRES_TITLE_CLASS: 'facettitle',
  FACET_BAR_WHEELS_TIRES_CONTAINER_CLASS: 'facetholder',
  FACET_BAR_WHEELS_TIRES_BODY_CLASS: 'facetbody',

  PRODUCT_REPEATER_CLASS:
    'grid grid--uniform grid--view-items use_align_height zoom-fade-animation zoomFade-animation',

  YOU_MAY_ALSO_LIKE: 'You may also like',
  RELATED_PRODUCT_CONTAINER_CLASS:
    'product-recommendations__inner half_row_mobile page-width zoom-fade-animation half_row_mobile zoomFade-animation',
  RELATED_PRODUCT_TITLE_CONTAINER_CLASS: 'section-header text-center',
  RELATED_PRODUCT_LIST_CLASS:
    'zoom-fade-animation grid grid--uniform grid--view-items use_align_height zoomFade-animation',

  GARAGE_CLASS: 'header_cart_info btn btn--primary show_cart_subtotal',
  GARAGE_ICON_CONTAINER_CLASS: 'btn btn--primary',
  GARAGE_SIZE: '<span class="header_cart_count garage-size" key="garage-size">{{size}}</span>',
};
